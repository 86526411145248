<template>
  <div class="pl-8 pr-8">
    <div class="ml-3 mr-3">
      <v-row>
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <!-- <h1 class="linguo__title-text mb-7">Linguo</h1> -->
          <v-layout justify-end>
            <img
              width="200"
              style="margin-left: -10px"
              class="mb-4"
              src="../../assets/img/LinguoN.png"
            />
          </v-layout>
          <p class="linguo__subtitle mb-4">Para desarrolladores</p>
        </v-col>
      </v-row>
    </div>

    <div
      style="
      text-align: initial;
      padding: 20px 30px;
        height: 500px;
        background-color: rgb(255 255 255);
        border-radius: 15px;
        border: 1px solid #e6e4e8;
      "
    >
      <div v-html="html2"></div>
    </div>

    <v-text-field outlined append-icon="mdi-send" @click:append="send()" class="mt-8" v-model="html" label="Escribe aquí tu pregunta?" @keyup.enter="send()" > </v-text-field>

    <!-- <v-row>
      <v-col class="my-0 py-0" cols="6">
        <p class="linguo__text mb-0 text-left">Escriba su texto</p>
      </v-col>
      <v-col class="my-0 py-0" cols="6">
        <p class="linguo__text mb-0 text-left">Acá esta su resultado</p>
      </v-col> -->
      <!-- <v-col class="my-0 py-0" cols="2">
        <v-btn class="mt-3" block small dark rounded color="#466be3">
          <strong> Copiar </strong>
        </v-btn>
      </v-col> -->
      <!-- <v-col class="my-0 py-0" cols="6">
        <v-card class="rounded-lg" outlined>
          <vue-editor
            :editor-toolbar="customToolbar"
            ref="editor"
            v-model="html"
          ></vue-editor>
        </v-card>

        <v-btn @click="send()" rounded block color="#466be3" class="mt-4" dark>
          <strong> mejorar </strong>
        </v-btn>
      </v-col> -->
      <!-- <v-col class="my-0 py-0" cols="6">
        <v-card outlined>
          <vue-editor
            :editor-toolbar="customToolbar"
            ref="editor"
            v-model="html2"
          ></vue-editor>
        </v-card>
      </v-col>
    </v-row> -->
    <v-overlay :value="loading">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Procesando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
      ],
      html: "",
      html2: "",
      token: {
        prompt_tokens: "",
        completion_tokens: "",
      },
      loading: false,
      token1: null,
      name: "",
      infoToken: {
        document: "",
      },
    };
  },
  created() {
    this.getToken();
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token1 = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    send() {
      this.loading = true;
      var data = {
        // instruction: "corregir ortografía, redacción, ser empatico",
        input: this.html,
      };
      Api.Auth()
        .spellingDev(data)
        .then((resp) => resp.json())
        .then((res) => {
          this.loading = false;
          console.log(res);
          // this.html2 = res.choices[0].text;
          this.html2 = res.choices[0].text;
          this.token = res.usage;
          this.html = "";
          var dataReport = {
            completion_tokens: res.usage.completion_tokens,
            prompt_tokens: res.usage.prompt_tokens,
            total_tokens: res.usage.total_tokens,
            original_message: this.html,
            new_message: res.choices[0].text,
          };
          // Api.Auth()
          //   .saveSpelling(this.token1, dataReport)
          //   .then((resP) => {
          //     console.log("se registró el dato");
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.linguo__title-text {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.linguo__subtitle {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #353535;
}

.linguo__text {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 59px;
  color: #353535;
}
</style>
